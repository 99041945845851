import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'ahooks'
import _ from 'lodash'
import dayjs from 'dayjs'
import { Badge, Button, Modal, Radio, Space } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { PlusOutlined } from '@ant-design/icons'

import { CusImage } from '@/components/CusImage'
import { CusTable } from '@/components/CusTable'
import { SPIN_DELAY } from '@/components/Loading'
import { useControllerRef } from '@/hooks/useAbortController'
import { PlatFormShow } from '../components/PlatFormShow'
import { apiGetRecords } from './apis'
import { handleReAuthorization } from './handleAuthorization'
import { IRecord, IReqData } from './interface'
import { SetLogisticsUrlConfigModal } from './SetLogisticsUrlConfigModal'
import { ShopModal } from './ShopModal'
import { popupSlot } from './utils'
import styles from './styles.module.less'

export const ShopList = () => {
  const { t } = useTranslation()
  const [authorization, setAuthorization] = useState<IReqData['authorization']>()

  const controllerRef = useControllerRef()
  const {
    loading,
    data,
    run: getList,
    mutate,
  } = useRequest<IRecord[], [IReqData]>(
    req => {
      controllerRef.current.abort()
      controllerRef.current = new AbortController()
      return apiGetRecords(req, controllerRef.current.signal)
    },
    { defaultParams: [{ authorization }] },
  )

  const columns: ColumnsType<IRecord> = [
    {
      title: t('ShopList.shang-dian-ming-cheng'),
      render: (_v, { shopName: v }) => v,
    },
    {
      title: t('ShopList.shang-dian-di-zhi'),
      render: (_v, { url: v }) => (
        <a target="_blank" href={v} rel="noreferrer">
          {v}
        </a>
      ),
    },
    {
      title: t('ShopList.shang-jia-logo'),
      width: 120,
      render: (_v, { logoUrl: v }) => v && <CusImage height={50} width={50} src={v} />,
    },
    {
      title: t('ShopList.ping-tai'),
      width: 120,
      render: (_v, { platForm }) => <PlatFormShow platForm={platForm} />,
    },
    {
      title: t('ShopList.shou-quan-zhuang-tai'),
      width: 160,
      render: (_v, { authorization }) => {
        if (authorization === 0) {
          return <Badge color="red" text={t('2-common.shi-bai')} />
        }
        if (authorization === 1) {
          return <Badge color="green" text={t('2-common.cheng-gong')} />
        }
      },
    },
    {
      title: t('ShopList.shou-quan-shi-jian'),
      width: 150,
      render: (_v, { authorizationTime: v }) => v && dayjs(v).format($F_YMDHms),
    },
    {
      title: t('2-common.cao-zuo'),
      width: 170,
      render: (_v, record) => {
        return (
          <Space size={0} direction="vertical">
            <Button
              type="link"
              onClick={() => {
                ShopModal.open({
                  record,
                  onEditSuccess({ shopName, logoUrl }) {
                    mutate(
                      oldVal =>
                        oldVal &&
                        oldVal.map(item => {
                          if (item.sysShopId === record.sysShopId) {
                            return { ...item, shopName, logoUrl: logoUrl ?? null }
                          }
                          return item
                        }),
                    )
                  },
                })
              }}
            >
              {t('2-common.bian-ji')}
            </Button>
            <Button
              type="link"
              onClick={() => {
                Modal.confirm({
                  title: t('ShopList.que-ren-yao-zhong-xin-shou-quan-ma'),
                  content: record.shopName,
                  onOk: async () => {
                    handleReAuthorization(_.pick(record, ['platForm', 'sysShopId', 'url']))
                    return new Promise(() => undefined)
                  },
                })
              }}
            >
              {t('ShopList.zhong-xin-shou-quan')}
            </Button>
            <Button type="link" onClick={() => SetLogisticsUrlConfigModal.open({ record })}>
              {t('ShopList.wu-liu-wang-zhi-she-zhi')}
            </Button>
          </Space>
        )
      },
    },
  ]

  return (
    <div className={styles.box}>
      <div className={styles.header}>
        <div className={styles.header_filter}>
          <span>{t('ShopList.shou-quan-zhuang-tai')}:&nbsp;</span>
          <Radio.Group
            buttonStyle="solid"
            value={authorization}
            onChange={e => {
              const val = e.target.value
              setAuthorization(val)
              getList({ authorization: val })
            }}
          >
            <Radio.Button value={undefined}>{t('2-common.quan-bu')}</Radio.Button>
            <Radio.Button value={1}>{t('2-common.cheng-gong')}</Radio.Button>
            <Radio.Button value={0}>{t('2-common.shi-bai')}</Radio.Button>
          </Radio.Group>
        </div>
        <div className={styles.header_action}>
          <Button type="primary" icon={<PlusOutlined />} onClick={() => ShopModal.open()}>
            {t('ShopList.xin-zeng-shang-dian')}
          </Button>
        </div>
      </div>

      <div className={styles.main}>
        <CusTable
          sticky
          rowKey="sysShopId"
          loading={{ spinning: loading, delay: SPIN_DELAY }}
          dataSource={data}
          columns={columns}
        />
      </div>

      <popupSlot.Slot />
    </div>
  )
}
