import { useEffect, useRef, useState } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import qs from 'qs'
import { Result } from 'antd'

import { Loading } from '@/components/Loading'
import { apiAfterAuthorization } from './apis'

export const ShopAuthorizationResult = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const initialSearchParams = useRef(searchParams).current
  const [loading, setLoading] = useState(true)
  const [isErr, setIsErr] = useState(false)

  const [countdown, setCountdown] = useState(5)
  const timerRef = useRef(0)

  // 授权
  useEffect(() => {
    if (initialSearchParams.size > 0) {
      const searchParams = qs.parse(initialSearchParams.toString())
      apiAfterAuthorization({
        sysShopId: `${searchParams.state || ''}`,
        code: `${searchParams.code || ''}`,
        shopUrl: `${searchParams.shop || ''}`,
        timestamp: Number(searchParams.timestamp),
      })
        .catch(err => {
          setIsErr(true)
          throw err
        })
        .finally(() => {
          setLoading(false)
          timerRef.current = window.setInterval(() => {
            setCountdown(prev => {
              if (prev - 1 <= 0) clearInterval(timerRef.current)
              return prev - 1
            })
          }, 1000)
        })
    }
  }, [initialSearchParams])

  if (initialSearchParams.size === 0 || countdown <= 0) {
    return <Navigate to="/biz/shop/list" replace />
  }

  if (loading) {
    return <Loading delay={0} />
  }

  if (isErr) {
    return (
      <Result
        status="error"
        title={t('ShopList.shou-quan-shi-bai')}
        subTitle={t('ShopList.shou-quan-shi-bai-tip', { countdown })}
      />
    )
  }

  return (
    <Result
      status="success"
      title={t('ShopList.shou-quan-cheng-gong')}
      subTitle={t('ShopList.shou-quan-cheng-gong-tip', { countdown })}
    />
  )
}
